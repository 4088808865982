import '../src/shared/bootstrap341.min.js'

//import Rails from "@rails/ujs"

// Make accessible for Electron and Mobile adapters
//window.Rails = Rails

// require("@rails/activestorage").start()
// require("channels")
// require("trix")
// require("@rails/actiontext")

// css
import 'stylesheets/complaint/base.scss'

import '../src/shared/jquery-ujs.js'
import '../src/shared/sweetalert_confirm_modal.js'
import '../src/shared/auto_numeric_min.js'
import '../src/shared/offline.min.js';

//Rails.start()

$( function() {
  $('input[data-role=money]').autoNumeric('init', { aSep: '', aDec: ',' } );
});